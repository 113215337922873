import React from "react";
import "./hero.css";
import { Message } from "./../lib/getMessage";

export default ({ scrollTo }) => (
  <div className="hero" style={{ width: "100%", height: "auto" }}>
    <div className="wrap">
      <div className="information">
        <h2>
          <Message dictKey="hero.zopsmart.technology" />
        </h2>
        <hr />
        <p>
          <Message dictKey="hero.zopsmart.technology.content" />
        </p>
      </div>
    </div>
  </div>
);
