import React from "react";
import Link from "gatsby-link";
import Header from "../../components/header";
import Hero from "../../components/hero";
import Layout from "../../components/layout";
import WithProviders from "../../components/WithProviders";

import smartstore from "../images/smartstore.png";
import smartstore2 from "../images/smartstore@2x.png";
import smartstore3 from "../images/smartstore@3x.png";

import productFinder from "../images/large-product-finder.png";
import productFinder2 from "../images/large-product-finder@2x.png";
import productFinder3 from "../images/large-product-finder@3x.png";

import zoppay from "../images/zoppay.png";
import zoppay2 from "../images/zoppay@2x.png";
import zoppay3 from "../images/zoppay@3x.png";

import intellipos from "../images/intlepos.png";
import intellipos2 from "../images/intlepos@2x.png";
import intellipos3 from "../images/intlepos@3x.png";

import iot from "../images/iot-device.png";
import iot2 from "../images/iot-device@2x.png";
import iot3 from "../images/iot-device@3x.png";
import { Message } from "../../lib/getMessage";
import "./product.css";

const Product = (props) => (
  <div className={props.name} id={props.name}>
    <div className="product wrap">
      <div className="product-details">
        <div>
          <div className="h2">{props.heading1}</div>
          <h1>{props.heading2}</h1>
          <p>{props.description}</p>
        </div>
        {props.cta && (
          <div className="cta-links">
            {props.cta.map((a, index) => (
              <Link to={a.href} key={a.name + " - " + index}>
                {a.name}
              </Link>
            ))}
          </div>
        )}
      </div>
      <div className="product-media">
        <img
          src={props.image.normal}
          srcSet={`${props.image.xx} 2x, ${props.image.xxx} 3x`}
          alt=""
          loading="lazy"
        />
      </div>
    </div>
  </div>
);

const ProductWrapper = (props) => {
  const { language, updateLanguage, direction } = props;
  return (
    <Layout
      direction={direction}
      showFooterForm
      language={language}
      location={props.location}
    >
      <div>
        <Header
          current="product"
          language={language}
          updateLanguage={updateLanguage}
          direction={direction}
        />
        <Hero scrollTo="#smart-store" />
        <div className="products">
          <Product
            name="smart-store"
            heading1="ZopNow"
            heading2="Smart Store"
            description={<Message dictKey="products.description" />}
            cta={[
              { href: "/eazy", name: <Message dictKey="products.eazy" /> },
              {
                href: "/enterprise",
                name: <Message dictKey="products.enterprise" />,
              },
            ]}
            image={{ normal: smartstore, xx: smartstore2, xxx: smartstore3 }}
          />
        </div>

        <Product
          name="product-finder"
          heading1={<Message dictKey="products.productfinder.heading1" />}
          heading2={<Message dictKey="products.productfinder.heading2" />}
          description={<Message dictKey="products.productfinder.description" />}
          image={{
            normal: productFinder,
            xx: productFinder2,
            xxx: productFinder3,
          }}
        />

        <Product
          name="zoppay"
          heading1={<Message dictKey="products.zoppay.heading1" />}
          heading2={<Message dictKey="products.zoppay.heading2" />}
          description={<Message dictKey="products.zoppay.description" />}
          image={{
            normal: zoppay,
            xx: zoppay2,
            xxx: zoppay3,
          }}
        />

        <Product
          name="intellipos"
          heading1={<Message dictKey="products.intellipos.heading1" />}
          heading2={<Message dictKey="products.intellipos.heading2" />}
          description={<Message dictKey="products.intellipos.description" />}
          image={{
            normal: intellipos,
            xx: intellipos2,
            xxx: intellipos3,
          }}
        />

        <Product
          name="grocery-express"
          heading1={<Message dictKey="products.groceryexpress.heading1" />}
          heading2={<Message dictKey="products.groceryexpress.heading2" />}
          description={
            <Message dictKey="products.groceryexpress.description" />
          }
          image={{
            normal: iot,
            xx: iot2,
            xxx: iot3,
          }}
        />
      </div>
    </Layout>
  );
};

export default WithProviders(ProductWrapper);
